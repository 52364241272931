// extracted by mini-css-extract-plugin
export var backgroundTransparent = "rgba(0,0,0,0)";
export var baseColor = "#000";
export var defaultForegroundColor = "#d9d9d9";
export var highlightColor = "#b99128";
export var imagePlaceholder = "index-module--image-placeholder--PHLNs";
export var overlayBackgroundBottom = "linear-gradient(0, #262626, transparent)";
export var overlayBackgroundColor = "#262626";
export var overlayBackgroundTop = "linear-gradient(0, transparent, #262626)";
export var rootBackgroundColor = "#151515";
export var section = "index-module--section--SkAcW";
export var textColor = "#fff";